import SatisfactionStep from "../../../../src/components/satisfaction-step";
import SatisfactionStepsContainer from "../../../../src/components/satisfaction-steps-container";
import ResponsiveHeading from "../../../../src/components/utils/responsive-heading";
import { Divider } from '@material-ui/core';
import { LearningProcessIcon, CertifiedTrainersIcon, QualityTrainingMaterialsIcon, FreeCourseRetakesIcon, ComputerTimeIcon, ConvenientLocationIcon, TrainingAtYourLocationIcon, ClassSizeIcon, HelpDeskFreeIcon, TrainingGuaranteeIcon } from "../../../../src/components/utils/svg-icons";
import * as React from 'react';
export default {
  SatisfactionStep,
  SatisfactionStepsContainer,
  ResponsiveHeading,
  Divider,
  LearningProcessIcon,
  CertifiedTrainersIcon,
  QualityTrainingMaterialsIcon,
  FreeCourseRetakesIcon,
  ComputerTimeIcon,
  ConvenientLocationIcon,
  TrainingAtYourLocationIcon,
  ClassSizeIcon,
  HelpDeskFreeIcon,
  TrainingGuaranteeIcon,
  React
};