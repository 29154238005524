// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classes-js": () => import("./../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-consulting-js": () => import("./../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-directions-js": () => import("./../src/pages/directions.js" /* webpackChunkName: "component---src-pages-directions-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-remote-learning-js": () => import("./../src/pages/remote-learning.js" /* webpackChunkName: "component---src-pages-remote-learning-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

